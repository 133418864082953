export default {
  getInstituteStatus(state) {
    return state.loading;
  },
  getInstitutes(state) {
    return state.myIinstitutionEnrollmets;
  },
  getInstitutions(state) {
    return {
      institutions: state.institutions,
      institionApproval: state.institionApproval,
    };
  },
  getFilteredUniqueInstitutions(state) {
    return state.filteredUniqueInstitutions;
  },
  getInstitutionUsers(state) {

    return state.institutionUsers
  },
  instituteCreationStatus(state) {
    return state.addInstitute;
  },
  getDefaultInstituteUserId(state) {
    return state.defaultInstitutionUserId;
  },
  getCurrentInstitution(state) {
    return state.selectedInstitute;
  },
  getSmsCount(state) {
    return {
      smsBalance: state.smsBalance,
    };
  },
  getInstituteSettings(state) {
    return state.instituteSettings;
  }
};

<template>
  <div id="app">
    <b-modal :title="
  $youtubeCategory != null
    ? $youtubeCategory + ' Tutorials'
    : 'Video Tutorials'
    " id="youtubeLinks" hide-footer centered size="xl">
      <youtube-tutorial></youtube-tutorial>
    </b-modal>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    // git reset test
  },
};
</script>

<style >
.table-responsive {
  width: auto;
  height: 400px !important;
  overflow-y: scroll;
  text-align: left !important;
  word-wrap: wrap;
  border-radius: 5px;
}

table thead {
  text-align: left !important;
}

table thead tr:last-child {
  position: sticky;
  top: 0;
}

* {
  font-size: 12px !important;
}

i {
  font-size: 24px !important;
}

table i {
  font-size: 18px !important;
}

button {
  border-radius: 5px !important;
}

.modal .modal-dialog .modal-content header {
  padding: 20px;
}

.modal .modal-dialog .modal-content header button {
  background-color: #cc0000;
  color: #ffffff;
  border-radius: none !important;
}

.modal .modal-dialog .modal-content header h5 {
  font-size: 16px;
  font-weight: 600;
}

body {
  background-color: #eeeeeb !important;
}

.blink-bg {
  /*color: #fff;*/
  /*padding: 10px;*/
  /*display: inline-block;*/
  /*border-radius: 5px;*/
  animation: blinkingBackground 4s infinite;
}

.multiselect i {
  top: none !important;
}

@keyframes blinkingBackground {
  0% {
    background-color: transparent;
  }

  /*25%     { background-color: #1056c0;}*/
  50% {
    border: 2px solid #6aff05;
    border-style: groove;
    font-size: 14px;
    font-weight: 600;
  }

  /*75%     { background-color: #254878;}*/
  100% {
    border: 2px solid #0192ff;
    border-style: groove;
    font-size: 14px;
    font-weight: 600;
  }
}

@font-face {
  font-family: "Roboto Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "ErasITC";
  src: url("./assets/fonts/ErasITCbyBT-Demi.otf");
}

body {
  font-family: "Roboto Regular";
}

table {
  font-size: 14px;
  font-weight: 600;
}

form {
  border: 2px solid #17a2b8;
  padding: 20px;
  border-radius: 5px;
}

form legend {
  font-size: 16px;
  font-weight: 600;
}

form select,
form input,
form textarea {
  font-size: 14px !important;
  font-weight: 600 !important;
}

form select option {
  font-size: 14px !important;
  font-weight: 600 !important;
}

textarea:focus,
input:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.userLogin form input {
  height: 50px;
}

.form-group-error {
  border-color: #f79483 !important;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.input-error-message {
  font-size: 12px;
  font-weight: 900;
  color: #f57f6c;
  margin-top: 0px !important;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}


.required input:after {
  content: "*";
  color: #cc0000 !important;
}

.required label {
  font-weight: bold;
}

.required label:before {
  color: #e32;
  content: ' *';
  display: inline;
}

.required-label:after {
  content: "*";
  color: red;
  display: inline;
}

</style>

import axios from "axios";
import store from "../../store";
import Vue from "vue";
import acl from "../../authorization/acl";
export default {
  async setUpInstitutes(context) {
    await context.commit("loadInstituteData");
    await context.dispatch("getUserInstitutionEnrolls");
    if (context.getters.getInstitutes.length == 0) {
      return await context.commit("loadInstituteData");
    }
    await context.dispatch("getInstitutions");
    if (context.getters.getInstitutes.length != 0) {
      await context.dispatch("getDefaultInstitutionUserId");
    } else {
      return await context.commit("loadInstituteData");
    }
    if (!context.getters.getDefaultInstituteUserId) {
      await context.dispatch(
        "setDefaultInstitutionUserId",
        context.getters.getInstitutes[0].id
      );
    }
    await context.dispatch("getDefaultInstituteId");
    await context.dispatch("getSmsCountBalance");
    context.dispatch("getCustomNamings");
    await acl.getRolesAndPermission();
    await context.commit("loadInstituteData");
  },
  async getUserInstitutionEnrolls(context) {
    let institutes = await axios
      .get(store.getters.getAPIKey.mainAPI + `/institutionusers/myinstitutions`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        error;
      });
    let fiteredInstitutes = {};
    let institutionUsers = {};
    await institutes.forEach((institute) => {
      Vue.set(institutionUsers, institute.institution.id, {
        userIds: [],
      });
    });
    await institutes.forEach((institute) => {
      Vue.set(
        fiteredInstitutes,
        institute.institution.id,
        institute.institution
      );

      institutionUsers[institute.institution.id].userIds.push(institute.id);
    });

    await context.commit("setInstitutes", institutes);
    await context.commit("setFilteredInstitutes", fiteredInstitutes);
    await context.commit("setInstitutionUsers", institutionUsers);
  },
  async getInstitutions(context) {
    const institutions = await axios
      .get(store.getters.getAPIKey.mainAPI + `/institutions`)
      .then((response) => {
        return response.data;
      });

    let institionApproval = true;
    await institutions.forEach((institution) => {
      if (institution.approved == 0) {
        return (institionApproval = false);
      }
    });

    await context.commit("setInstitutions", {
      institutions,
      institionApproval,
    });
  },
  async getDefaultInstitutionUserId(context) {
    const url =
      store.getters.getAPIKey.mainAPI + `/user/setting/default_institutionUser`;
    const defaultInstitutionUserId = await axios.get(url).then((response) => {
      return response.data;
    });

    context.commit("getDefaultInstituteUserId", defaultInstitutionUserId);
  },
  async setDefaultInstitutionUserId(context, payload) {

    context;
    localStorage.removeItem("institutionUserRolesArray");
    const url =
      store.getters.getAPIKey.mainAPI + `/user/setting/default_institutionUser`;
    await axios
      .post(url, { default_institutionUser: payload })
      .then((response) => {
        // this.defaultInstitutionUserId = response.data.default_institutionUser;
        // this.instituteId = institutionUserId;
        response;
      });
  },
  async getDefaultInstituteId(context) {
    const url = store.getters.getAPIKey.defaultInstitute;
    const defaultInstitutionId = await axios.get(url).then((response) => {
      return response.data != ""
        ? response.data
        : context.getters.getInstitutes[0].institution.id;
    });

    await context.dispatch("getInstitution", defaultInstitutionId);
  },
  async getInstitution(context, payload) {
    const selectedInstitute = await axios
      .get(store.getters.getAPIKey.mainAPI + `/institutions/` + payload)
      .then((response) => {
        return response.data;
      });
    await context.commit("getCurrentInstitution", selectedInstitute);
  },
  async getSmsCountBalance(context) {
    const url =
      store.getters.getAPIKey.mainAPI + "/institution/setting/sms_balence";
    const smsBalance = await axios.get(url).then((response) => {
      return response.data;
    });
    await context.commit("setSmsBalance", smsBalance);
  },
};

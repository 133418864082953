import Vue from "vue";
import VueRouter from "vue-router";

const EnterCredential = () => import("../Home/EnterCredential.vue");
// const HelpCenter = () =>import(/* webpackChunkName: "home" */ "../Home/HelpCenter.vue");

const TermsAndConditions = () => import("../Home/TermsAndConditions.vue");
const HelpCenter = () => import("../Home/help_center/HelpCenter.vue");
const Faqs = () => import("../Home/help_center/Faqs.vue");
const HelpAndQuestions = () => import("../Home/help_center/helpAndQuestions.vue");

const MainDashboard = () => import("../dashboard/MainDashboard.vue");
const Verification = () => import("../Home/Verification.vue");
const ResetPassword = () => import("../Home/ResetPassword.vue");
// const UserProfile = () => import("../dashboard/UserProfile.vue");
const DashboardHome = () => import("../dashboard/DashboardHome.vue");
const Institute = () => import("../dashboard/Institute.vue");
const Courses = () => import("../dashboard/Courses.vue");
const SendingInfo = () =>
  import("../components/templates/Dashboard/sms_email/SendingInfo.vue");
const AttendenceMarking = () =>
  import("../components/templates/Dashboard/attendence/AttendenceMarking.vue");
const NotFound = () => import("../components/templates/NotFound.vue");
const AcceptInvitation = () =>
  import("../components/templates/Dashboard/AcceptInvitation.vue");
const manageUniversityExamQuestions = () => import(
    "../components/templates/Dashboard/university_exam/manageUniversityExamQuestions.vue"
  );
const manageUniversityExamEvaluations = () => import(
  "../components/templates/Dashboard/university_exam/OfflineUniversityExam.vue"
);
const ExamQuestions = () =>
  import("../components/templates/Dashboard/examination/ExamQuestions.vue");
const TypeOfExam = () =>
  import(
    "../components/templates/Dashboard/examination/typeofexam/TypeOfExam.vue"
  );
const ExamAnswers = () =>
  import("../components/templates/Dashboard/examination/ExamAnswers.vue");
const ExamsEvaluation = () =>
  import("../components/templates/Dashboard/examination/ExamsEvaluation.vue");
const AssignmentQuestions = () =>
  import(
    "../components/templates/Dashboard/assignment/AssignmentQuestions.vue"
  );
const AssignmentAnswers = () =>
  import("../components/templates/Dashboard/assignment/AssignmentAnswers.vue");
const AssignmentEvaluation = () =>
  import(
    "../components/templates/Dashboard/assignment/AssignmentEvaluation.vue"
  );
const ViewScore = () =>
  import("../components/templates/Dashboard/assignment/ViewScore.vue");
const FillPersonalProfile = () =>
  import("../components/templates/Dashboard/user/FillPersonalProfile.vue");
const CourseOutcomeExcel = () =>
  import(
    "../components/templates/Dashboard/Course/excel/CourseOutcomeExcel.vue"
  );
const CourseModuleExcel = () =>
  import(
    "../components/templates/Dashboard/Course/excel/CourseModuleExcel.vue"
  );
const LessonPlanExcel = () =>
  import("../components/templates/Dashboard/Course/excel/LessonPlanExcel.vue");
const SurveyQuestions = () =>
  import("../components/templates/Dashboard/survey/SurveyQuestions.vue");
const SurveyAnswers = () =>
  import("../components/templates/Dashboard/survey/SurveyAnswers.vue");
const SurveyAnswersCourseExit = () =>
  import(
    "../components/templates/Dashboard/survey/SurveyAnswersCourseExit.vue"
  );
const SurveyAnswersProgramExit = () =>
  import(
    "../components/templates/Dashboard/survey/SurveyAnswersProgramExit.vue"
  );
const SurveyQuestionsExcel = () =>
  import(
    "../components/templates/Dashboard/survey/excel/SurveyQuestionsExcel.vue"
  );
  const SurveyResults = () =>
  import("../components/templates/Dashboard/survey/SurveyResults.vue");
const ApprovalQuestions = () =>
  import(
    "../components/templates/Dashboard/survey/survey_approvals/ApprovalQuestions.vue"
  );
const ApprovalAnswers = () =>
  import(
    "../components/templates/Dashboard/survey/survey_approvals/ApprovalAnswers.vue"
  );
const ListingFormsForApprovals = () =>
  import(
    "../components/templates/Dashboard/survey/survey_approvals/forms_for_approvals/ListingFormsForApprovals.vue"
  );
const RegisterNewUser = () =>
  import(
    "../components/templates/Dashboard/survey/survey_approvals/public_url_unapproved_users/RegisterNewUser.vue"
  );

const PrivacyPolicy = () => import("../Home/PrivacyPolicy.vue");

const DetailedApprovalReport = () =>
  import(
    "../components/templates/Dashboard/survey/survey_approvals/approval_reports/DetailedApprovalReport.vue"
  );

const MyProfile = () =>
  import("../components/templates/Dashboard/myprofile/MyProfile.vue");
const UserProfile = () =>
  import("../components/templates/Dashboard/userprofile/UserProfile.vue");

const PoAttainment = () =>
  import("../components/templates/Dashboard/poAttainment/dashboard.vue");

const UsersubgroupAttendanceMonitoringDash = () =>
  import(
    "../components/templates/Dashboard/attendence/monitoring/UsersubgroupAttMonitoringDash.vue"
  );
  const auditorLayout = () => import(
    "../components/auditor/auditorLayout.vue"
  );
  const auditorLogin = () => import(
    "../components/auditor/auditorLogin.vue"
  );
  const auditorDash = () => import(
    "../components/auditor/auditorDashboard.vue"
  );
  const publicexamLayout = () =>
    import(
      "../components/templates/Dashboard/public_exam/publicexamLayout.vue");
  const publicexamLogin = () =>
    import(
      "../components/templates/Dashboard/public_exam/publicexamLogin.vue");
  const publicexamDash = () =>
    import(
      "../components/templates/Dashboard/public_exam/Dashboard.vue");
  const PublicExamAnswers = () =>
    import(
      "../components/templates/Dashboard/public_exam/PublicExamAnswers.vue"
    );
  const PublicExamReportSummary = () =>
    import(
      "../components/templates/Dashboard/public_exam/PublicExamSummaryReport.vue"
    );
  const PublicExamFacultyScoreCard = () =>
    import(
      "../components/templates/Dashboard/public_exam/PublicExamViewScore.vue"
    );
  const PublicExamScore = () =>
    import(
      "../components/templates/Dashboard/public_exam/PublicExamEvaluation.vue"
    );
  
Vue.use(VueRouter);

const routes = [
  { path: "/", component: EnterCredential, name: "login" }, 
  {
    path: "/auditor",
    component: auditorLayout,
    name: "auditorLayout",
    children: [
      { path: "login/:email/:otp", component: auditorLogin, name: "auditorLogin" },
      { path: "login/:email?", component: auditorLogin, name: "auditorLogin" },
      { path: "dashboard", component: auditorDash, name: "auditorDash" },
    ],
  },
  {
    path: "/publicexam",
    component: publicexamLayout,
    name: "publicexamLayout",
    children: [
      { path: "login/:email?",component: publicexamLogin, name: "publicexamLogin"},
      { path: "login/:email/:otp",component: publicexamLogin, name: "publicexamLogin"},
      { path: "dashboard",component: publicexamDash, name: "publicexamDash"},
    ],
  }, 
  { path: "/publicexamanswers/:exam_id/:type",component: PublicExamAnswers,name: "PublicExamAnswers"},
  {path: "/studentScore/:exam_id/:type",component: PublicExamScore,name: "PublicExamScore"},
  {path: "/summary_report/:exam_id",component: PublicExamReportSummary, name: "PublicExamReportSummary"},
  {path: "/facultyScoreCard/:exam_id",component: PublicExamFacultyScoreCard,name: "PublicExamScore"},
  { path: "/email/verify/:id/:token", component: Verification },
  { path: "/pf/:public_id", component: RegisterNewUser, name: "publicURL" },
  {
    path: "/ezygo/dash",
    component: MainDashboard,
    children: [
      { path: "/home", component: DashboardHome, name: "home" },
      { path: "/myprofile", component: MyProfile, name: "MyProfile" },
      {
        path: "/userprofile/:profileId",
        component: UserProfile,
        name: "UserProfile",
      },
      {
        path: "/poattainment/:usersubgroupId",
        component: PoAttainment,
        name: "PoAttainment",
      },
      {
        path: "/subgroupattendancemonitoring/:usersubgroup",
        component: UsersubgroupAttendanceMonitoringDash,
        name: "UsersubgroupAttendanceMonitoring",
      },
      { path: "/institute", component: Institute, name: "institute" },
      { path: "/courses", component: Courses, name: "courses" },
      { path: "/attendence", component: AttendenceMarking },
      { path: "/smsemail", component: SendingInfo, name: "sendInfo" },
      {
        path: "/exam_questions/:exam_id",
        component: ExamQuestions,
        name: "ExamQues",
      },
      {
        path: "/university_exam/manage_exam_question/:exam_id/:course_id",
        component: manageUniversityExamQuestions,
        name: "manageUniversityExamQuestions",
      },
      {
        path: "/university_exam/manage_exam_evaluation/:exam_id/:course_id",
        component: manageUniversityExamEvaluations,
        name: "manageUniversityExamEvaluations",
      },
      {
        path: "/answers/:exam_id",
        component: ExamAnswers,
        name: "ExamAnswers",
      },
      {
        path: "/marks/:exam_id",
        component: TypeOfExam,
        name: "ExamEvaluation",
      },
      {
        path: "/evaluate/:exam_id",
        component: ExamsEvaluation,
        name: "ExamScore",
      },
      {
        path: "/assignment_ques/:assignment_id",
        component: AssignmentQuestions,
        name: "AssignmentQues",
      },
      {
        path: "/assignment_answers/:assignment_id",
        component: AssignmentAnswers,
        name: "AssignmentAns",
      },
      {
        path: "/assignment_marks/:assignment_id",
        component: AssignmentEvaluation,
        name: "AssignmentEval",
      },
      {
        path: "/assignment_score/:assignment_id",
        component: ViewScore,
        name: "AssignmentScore",
      },
      {
        path: "/course_outcome_csv/:course_id",
        component: CourseOutcomeExcel,
        name: "CourseOutcomeCSV",
      },
      {
        path: "/course_module_csv/:course_id",
        component: CourseModuleExcel,
        name: "CourseModuleCSV",
      },
      {
        path: "/lesson_plan_csv/:course_id",
        component: LessonPlanExcel,
        name: "CourseLessonsCSV",
      },
      {
        path: "/survey_questions/:survey_id",
        component: SurveyQuestions,
        name: "SurveyQues",
      },
      {
        path: "/survey_answers/:survey_id",
        component: SurveyAnswers,
        name: "student_feedback",
      },
      {
        path: "/survey_answers_course_exit/:survey_id",
        component: SurveyAnswersCourseExit,
        name: "course_exit",
      },
      {
        path: "/survey_answers_programme_exit/:survey_id",
        component: SurveyAnswersProgramExit,
        name: "programme_exit",
      },
      {
        path: "/survey_questions_excel/:survey_id",
        component: SurveyQuestionsExcel,
        name: "SurveyQuesExcel",
      },
      {
        path: "/survey_results/:survey_id",
        component: SurveyResults,
        name: "Survey Rsult Update",
      },
      {
        path: "/approval_questions/:approval_id",
        component: ApprovalQuestions,
        name: "ApprovalQues",
      },
      {
        path: "/approval_answers/:approval_id/onlineregapplications/:application_id",
        component: ApprovalAnswers,
        name: "ApprovalAns",
      },
      // {
      //   path: "/approval_answers/:approval_id/onlineregapplications/application_id",
      //   component: ApprovalAnswers,
      //   name: "ApprovalAns",
      // },
      {
        path: "/approval_forms/:approval_id",
        component: ListingFormsForApprovals,
        name: "ApprovalForms",
      },
      {
        path: "/approval_report/:approval_id",
        component: DetailedApprovalReport,
        name: "DetailedApprovalReport",
      },
      // {
      //   path: "/userprofile/",
      //   component: Profile,
      //   name: "UserProfile",
      // },
    ],
  },
  { path: "/privacy", component: PrivacyPolicy, name: "privacy" },
  { path: "/terms_conditions", component: TermsAndConditions, name: "terms" },
  {
    path: "/help_center",
    component: HelpCenter,
    name: "helpCenter",
  },
  {
    path: "/help_questions",
    component: HelpAndQuestions,
    name: "helpAndQuestions",
  },
  {
    path: "/ezygo_faqs",
    component: Faqs,
    name: "faqs",
  },
  { path: "/personal", component: FillPersonalProfile },
  { path: "/reset", component: ResetPassword },
  { path: "/accept/:code", component: AcceptInvitation, name: "invitation" },
  //All valid routes should be above this route
  { path: "/:notFound(.*)", component: NotFound },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

const ezygoLinks = {
  Courses: [
    {
      link: "https://www.youtube.com/watch?v=zQKnIPRKfMs&t=3s",
      name:
        "How to add courses (theory/lab) to a subgroup (class/semester) | Managing students for electives",
      thumbnail: "https://img.youtube.com/vi/zQKnIPRKfMs/mqdefault.jpg",
      instructor: true,
      student: false,
    },
    {
      link: "https://www.youtube.com/watch?v=mjs3EKlmJr4",
      name:
        "Downloading Course File in ezygo.app -PART 1 | Schedule, Outcomes, Lesson plan, CO-PO/PSO, Roll List",
      thumbnail: "https://img.youtube.com/vi/mjs3EKlmJr4/mqdefault.jpg",
      instructor: true,
      student: false,
    },
    {
      link: "https://www.youtube.com/watch?v=MYTArv3i3QM",
      name:
        "Downloading Course File in ezygo.app - PART 2 | Attendance Reports and Course Coverage Report",
      thumbnail: "https://img.youtube.com/vi/MYTArv3i3QM/mqdefault.jpg",
      instructor: true,
      student: false,
    },
    {
      link: "https://www.youtube.com/watch?v=pkRr7egtwwM",
      name:
        "Downloading Course File in ezygo.app - PART 3 | Continuous Evaluation Report",
      thumbnail: "https://img.youtube.com/vi/pkRr7egtwwM/mqdefault.jpg",
      instructor: true,
      student: false,
    },
  ],
  Attendance: [
    {
      link: "https://www.youtube.com/watch?v=DKg5LFk9urI",
      name:
        "How to mark attendance in ezygo.app | Attaching topic and delivery method while marking Attendance",
      thumbnail: "https://img.youtube.com/vi/DKg5LFk9urI/mqdefault.jpg",
      instructor: true,
      student: false,
    },
  ],
  Timetable: [
    {
      link: "https://www.youtube.com/watch?v=38NIZDQoUY0&t=20s",
      name:
        "How to add time table in ezygo.app | How to view instructor schedule in ezygo.app | Tutorial 7",
      thumbnail: "https://img.youtube.com/vi/38NIZDQoUY0/mqdefault.jpg",
      instructor: true,
      student: false,
    },
  ],
  Activity: [
    {
      link: "https://www.youtube.com/watch?v=NQpD97Y6Jt4",
      name:
        "How to create activities for students in ezygo.app - Assignments and Assessments",
      thumbnail: "https://img.youtube.com/vi/NQpD97Y6Jt4/mqdefault.jpg",
      instructor: true,
      student: false,
    },
    {
      link: "https://www.youtube.com/watch?v=Am_WFupLgUA",
      name:
        "How to evaluate activities in ezygo.app - Type 1 and Type 2 Evaluation Explained",
      thumbnail: "https://img.youtube.com/vi/Am_WFupLgUA/mqdefault.jpg",
      instructor: true,
      student: false,
    },
    {
      link: "https://www.youtube.com/watch?v=AsNvanSarJQ",
      name:
        "How to submit activities in ezygo.app -Submitting Assignments and Submitting Answers for Assessments",
      thumbnail: "https://img.youtube.com/vi/AsNvanSarJQ/mqdefault.jpg",
      instructor: true,
      student: true,
    },
  ],
  LiveClass: [
    {
      link: "https://www.youtube.com/watch?v=f_-ZtpOzFAU",
      name:
        "How to conduct or attend a live class in ezygo.app | Creating/cancelling live class with SMS alerts",
      thumbnail: "https://img.youtube.com/vi/f_-ZtpOzFAU/mqdefault.jpg",
      instructor: true,
      student: false,
    },
  ],
  Survey: [
    {
      link: "https://www.youtube.com/watch?v=lQmN2_Tlz8k",
      name:
        "How to conduct course exit and program exit surveys | Downloading survey reports in ezygo.app",
      thumbnail: "https://img.youtube.com/vi/lQmN2_Tlz8k/mqdefault.jpg",
      instructor: true,
      student: false,
    },
    {
      link: "https://www.youtube.com/watch?v=F-CzoO7n1xY",
      name:
        "How to create instructor feedback surveys | How to participate in instructor feedback surveys",
      thumbnail: "https://img.youtube.com/vi/F-CzoO7n1xY/mqdefault.jpg",
      instructor: true,
      student: true,
    },
  ],
  Approval: [
    {
      link: "https://www.youtube.com/watch?v=G6nhXgv15WM",
      name:
        "How to create ezygo approval forms | Adding form questions | Adding approvers and approving order",
      thumbnail: "https://img.youtube.com/vi/G6nhXgv15WM/mqdefault.jpg",
      instructor: true,
      student: false,
    },
    {
      link: "https://www.youtube.com/watch?v=1Gd0Xbgde1U",
      name:
        "How to approve ezygo approval forms | Viewing and downloading approval form reports and attachments",
      thumbnail: "https://img.youtube.com/vi/1Gd0Xbgde1U/mqdefault.jpg",
      instructor: true,
      student: true,
    },
    {
      link: "https://www.youtube.com/watch?v=NNKvIEXNR24",
      name:
        "How to submit ezygo approval forms | Viewing approval status after form submission",
      thumbnail: "https://img.youtube.com/vi/NNKvIEXNR24/mqdefault.jpg",
      instructor: true,
      student: false,
    },
  ],
  UniversityExam: [
    {
      link: "https://www.youtube.com/watch?v=NK_D4k0qrSY",
      name: "How to update university exam marks in ezygo.app",
      thumbnail: "https://img.youtube.com/vi/NK_D4k0qrSY/mqdefault.jpg",
      instructor: true,
      student: false,
    },
  ],
  Groups: [
    {
      link: "https://www.youtube.com/watch?v=UK5oHOe6Akk",
      name:
        "How to create an institute in ezygo.app | Creating groups (dept.) & subgroups (classes) | Tutorial 2",
      thumbnail: "https://img.youtube.com/vi/UK5oHOe6Akk/mqdefault.jpg",

      instructor: true,
      student: false,
    },
    {
      link: "https://www.youtube.com/watch?v=OKhSUHHvdjg",
      name:
        "How to add faculty to groups (depts.) and students to subgroups (classes) | Editing student profile",
      thumbnail: "https://img.youtube.com/vi/OKhSUHHvdjg/mqdefault.jpg",
      instructor: true,
      student: false,
    },
    {
      link: "https://www.youtube.com/watch?v=zQKnIPRKfMs&t=2s",
      name:
        "How to add courses (theory/lab) to a subgroup (class/semester) | Managing students for electives",
      thumbnail: "https://img.youtube.com/vi/zQKnIPRKfMs/mqdefault.jpg",
      instructor: true,
      student: false,
    },
    {
      link: "https://www.youtube.com/watch?v=DtvBUkNzvHU",
      name:
        "How to promote/transfer students to a new class/subgroup in ezygo.app | Promoting students explained",
      thumbnail: "https://img.youtube.com/vi/DtvBUkNzvHU/mqdefault.jpg",
      instructor: true,
      student: false,
    },
  ],
};

export default ezygoLinks;

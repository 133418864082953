export default {
  loadInstituteData(state) {
    state.loading = !state.loading;
  },
  setInstitutes(state, payload) {
    if (payload.length == 0) {
      state.addInstitute = true;
    } else {
      state.addInstitute = false;
    }
    state.myIinstitutionEnrollmets = payload;
  },
  setFilteredInstitutes(state, payload) {
    state.filteredUniqueInstitutions = payload;
  },
  setInstitutionUsers(state, payload) {
    state.institutionUsers = payload;
  },
  setInstitutions(state, payload) {
    state.institionApproval = payload.institionApproval;
    state.institutions = payload.institutions;
  },
  getDefaultInstituteUserId(state, payload) {
    state.defaultInstitutionUserId = payload;
  },
  getCurrentInstitution(state, payload) {
    state.selectedInstitute = payload;
  },
  setSmsBalance(state, payload) {
    state.smsBalance = payload;
  },
  enableInstituteCreation(state) {
    state.addInstitute = !state.addInstitute;
  },
  setInstituteSettings(state) {
    state.instituteSettings = !state.instituteSettings;
  },
};

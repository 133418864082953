//acess controll list
/* eslint-disable */
import axios from "axios";
import store from "../store";

// Vue.directive('can', {
//   bind: function (el, binding, vnode) {
//     if (! JSON.parse(localStorage.getItem('permissions'))
//         .includes(binding.expression
//           .replace(/'/g, "")
//             .replace(/"/g, ""))) {
//         vnode.elm.style.display = "none";
//     }
//   }
// })

//get user permissions
//if you are using token or something you could do something like
// axios.post('/users/permissions', {
//     token: localStorage.getItem('token')
//     }).then(success => {
//      localStorage.setItem("permissions", JSON.stringify(success.data));
//     });
//     //or just simply
//     axios.get('/users/permissions')
//     .then(success => {
//      localStorage.setItem("permissions", JSON.stringify(success.data));
//     });
//     //create v-can directive
//     Vue.directive('can', {
//       bind: function (el, binding, vnode) {
//         if (! JSON.parse(localStorage.getItem('permissions'))
//             .includes(binding.expression
//               .replace(/'/g, "")
//                 .replace(/"/g, ""))) {
//            vnode.elm.style.display = "none";
//         }
//       }
//     })
// function name() {
//   return 'class imported';
// }
// (function ($) {
//   var logIn = function () {
//               return 'true';
//           };
//   })();

let acl = {
  async getRolesAndPermission() {
    //clear existing data
    const checkPermissions = localStorage.getItem("userPermissions");
    if (checkPermissions) {
      localStorage.removeItem("userPermissions");
    }

    let permissionsResponse = [];
    await axios
      .get(store.getters.getAPIKey.mainAPI + "/institutionuser/myroles")
      .then((response) => {
        permissionsResponse = response.data;
      });

    let permissions = "";
    permissions = await this.compilePermission(permissionsResponse);
    permissions = JSON.stringify(permissions);
    localStorage.setItem("userPermissions", permissions);

    //store available institution user roles also
    let institutionUserRolesArray = [];
    institutionUserRolesArray = await this.compileInstitutionUserRoles(
      permissionsResponse
    );
    institutionUserRolesArray = await JSON.stringify(institutionUserRolesArray);
    await localStorage.setItem(
      "institutionUserRolesArray",
      institutionUserRolesArray
    );

    return permissions;
  },
  async isUserGranted(permission) {
    // localStorage.removeItem("userPermissions"); //Commented out for debugging

    var userPermissionsData = localStorage.getItem("userPermissions");
    if (!userPermissionsData) {
      userPermissionsData = await this.getRolesAndPermission();
    }
    var userPermissions = JSON.parse(userPermissionsData);

    var access = await userPermissions.includes(permission);
    if (access) {
      return true;
    }

    return false;
  },
  async compilePermission(rolesAndPermissions) {
    let userPermissions = [];

    // userPermissions.push("test123"); //for all type users

    // Resource permission----------------------------------------------------
    //  [ viewResouces, manageResouces, viewResouce, viewResouce:1 ]
    //-------------------------------institution---------------------------------
    if (rolesAndPermissions.institutionRole.name == "admin") {
      userPermissions.push("manageAuditors", "manageIqacStaff");
      // institution admin permissions here
      // userPermissions.push("update-institution", "delete-institution");
      // userPermissions = []
      userPermissions.push(
        "institutionAdmin",
        "viewInstitution",
        "viewInstitutionUsers",
        "editInstituitionSettings",
        "canInviteUsers",
        "sendSmsEmail"
      ); //institution permission
      userPermissions.push(
        "mainGroupAdmin",
        "viewGroupsSubgroupsTab",
        "editGroupSubgroups",
        "mainAdmin"
      ); //Groups Permissions
      userPermissions.push(
        "ViewInstitutionTimeTableTab",
        "ViewInstructorTimeTableTab",
        "editTimeTable"
      ); //instructor timetable
      userPermissions.push("viewReportsTab", "assessmentReportsTab");
      userPermissions.push("viewCoursesTab", "editCourse"); // course permissions
      userPermissions.push("viewReportsTab");
      userPermissions.push(
        "subgroupsInAttendenceReport",
        "coursesInAttendenceReport",
        "groupAdminAssessmentReport",
        "subgroupAdminAssessmentReport",
        "instructorAssessmentReport",
        "surveyReport",
        "surveyCourseReport",
        "surveyParticipantsReport"
      ); //Report
      userPermissions.push(
        "viewSurveyTab",
        "surveyAdmin",
        "surveyInstituteAdmin"
      ); // Survey Permissions
      userPermissions.push(
        "viewSurveyApprovalTab",
        "SurveyApprovalAdmin",
        "surveyApprovalQuestions",
        "surveyApprovalReports"
      ); //Survey Approvals
      userPermissions.push("universityExam"); //University/BoardExam
    }
    if (rolesAndPermissions.institutionRole.name == "teacher") {
      userPermissions.push("viewInstitution"); //institution permission
      userPermissions.push("viewReportsTab");
      userPermissions.push("viewSurveyApprovalTab", "SurveyApprovalAdmin");
    }
    if (rolesAndPermissions.institutionRole.name == "student") {
      userPermissions.push("viewInstitution"); //institution permission
      userPermissions.push("viewSurveyApprovalTab", "SurveyApprovalStudent");
    }
    if (rolesAndPermissions.additionalRoles.includes('iqac_staff')) {
      userPermissions.push("manageAuditors");
    }
    // manageInstitution => user can edit , update resouce
    //------------------------------Maingroup------------------------------

    //-------------------------------Maingroup---------------------------------
    await rolesAndPermissions.maingroupRoles.forEach((maingroup) => {
      //add group.id to the permission
      if (maingroup.pivot.maingrouprole_id == 1) {
        userPermissions.push("viewInstitution", "sendSmsEmail"); //institution permission
        userPermissions.push(
          "groupAdmin",
          "viewGroupsSubgroupsTab",
          "editGroupSubgroups"
        ); //Group Subgroups Permission
        userPermissions.push("viewCoursesTab", "editCourse"); // course permissions
        userPermissions.push(
          "ViewInstitutionTimeTableTab",
          "ViewInstructorTimeTableTab",
          "editTimeTable"
        ); //instructor timetable
        userPermissions.push(
          "viewSurveyTab",
          "surveyAdmin",
          "surveyGroupAdmin"
        ); // Survey Permissions
        userPermissions.push("viewReportsTab");
        userPermissions.push(
          "subgroupsInAttendenceReport",
          "coursesInAttendenceReport",
          "groupAdminAssessmentReport",
          "surveyReport",
          "surveyParticipantsReport"
        );
        userPermissions.push(
          "viewSurveyApprovalTab",
          "SurveyApprovalAdmin",
          "surveyApprovalReports"
        ); //Survey Approvals
        userPermissions.push("universityExam"); //University/BoardExam
        userPermissions.push("mainGroupAdmin"); //institution permission
      }
      // if (maingroup.pivot.maingrouprole_id == 2) {

      // }
    });

    //-------------------------------group---------------------------------
    await rolesAndPermissions.groupRoles.forEach((group) => {
      //add group.id to the permission
      if (group.pivot.usergrouprole_id == 1) {
        // userPermissions = []
        // userPermissions.push("update-group-"+group.id, "delete-group-"+group.id);
        // group admin permissions here
        userPermissions.push("viewInstitution", "sendSmsEmail"); //institution permission
        userPermissions.push(
          "groupAdmin",
          "viewGroupsSubgroupsTab",
          "editGroupSubgroups"
        ); //Group Subgroups Permission
        userPermissions.push("viewCoursesTab", "editCourse"); // course permissions
        userPermissions.push(
          "ViewInstitutionTimeTableTab",
          "ViewInstructorTimeTableTab",
          "editTimeTable"
        ); //instructor timetable
        userPermissions.push(
          "viewSurveyTab",
          "surveyAdmin",
          "surveyGroupAdmin"
        ); // Survey Permissions
        userPermissions.push("viewReportsTab");
        userPermissions.push(
          "subgroupsInAttendenceReport",
          "coursesInAttendenceReport",
          "groupAdminAssessmentReport",
          "surveyReport",
          "surveyParticipantsReport"
        );
        userPermissions.push(
          "viewSurveyApprovalTab",
          "SurveyApprovalAdmin",
          "surveyApprovalReports"
        ); //Survey Approvals
        userPermissions.push("universityExam"); //University/BoardExam
      }
      if (group.pivot.usergrouprole_id == 2) {
        // userPermissions = []
        userPermissions.push("viewInstitution"); //institution permission
      }
    });

    //-------------------------------subgroup---------------------------------
    await rolesAndPermissions.subgroupRoles.forEach((subgroup) => {
      //add subgroup.id to the permission
      if (subgroup.pivot.usersubgrouprole_id == 1) {
        // userPermissions = []
        // userPermissions.push("update-subgroup-"+subgroup.id, "delete-subgroup-"+subgroup.id);
        // // subgroup admin permissions here
        userPermissions.push("viewInstitution", "sendSmsEmail"); //institution permission
        userPermissions.push("viewGroupsSubgroupsTab"); //Group Subgroups Permission
        userPermissions.push("subgroupAdmin", "viewSubgroupsOnly"); //Subgroups Permission
        userPermissions.push("viewCoursesTab", "editCourse"); // course permissions
        userPermissions.push("ViewInstitutionTimeTableTab", "editTimeTable"); //instructor timetable

        userPermissions.push("viewReportsTab");
        userPermissions.push(
          "subgroupsInAttendenceReport",
          "coursesInAttendenceReport",
          "subgroupAdminAssessmentReport",
          "surveyReport",
          "surveyParticipantsReport"
        );
        userPermissions.push(
          "viewSurveyApprovalTab",
          "SurveyApprovalAdmin",
          "surveyApprovalReports"
        ); //Survey Approvals
        userPermissions.push("universityExam"); //University/BoardExam
      }
      if (subgroup.pivot.usersubgrouprole_id == 2) {
        // userPermissions = []
        // subgroup student permissions here
        userPermissions.push("viewInstitution"); //institution permission
        userPermissions.push("ViewInstitutionTimeTableTab", "viewStudentTab"); //instructor timetable
      }
    });

    //-------------------------------course---------------------------------
    await rolesAndPermissions.courseRoles.forEach((course) => {
      //add course.id to the permission
      if (course.pivot.courserole_id == 1) {
        // userPermissions = []
        // userPermissions.push("update-course-"+course.id, "delete-course-"+course.id);
        // course instrucytor permissions here
        // userPermissions.push("viewManageAttendanceTab") //Attendence tab
        userPermissions.push("viewInstitution", "courseInstructor"); //institution permission
        userPermissions.push("myCourses"); // course permissions
        userPermissions.push("viewAssessmentTab", "instructorAssessmentAccess"); //Assessment
        userPermissions.push("viewAssignmentTab", "instructorAssignment"); //Assignment
        userPermissions.push("viewLiveClassTab", "instructorLiveClass"); //Live Classes
        userPermissions.push("mySchedule"); //instructor timetable
        userPermissions.push("viewManageAttendanceTab"); //Attendence permission
        userPermissions.push(
          "viewSurveyTab",
          "surveyAdmin",
          "surveyInstructorAdmin"
        ); // Survey Permissions
        userPermissions.push(
          "viewReportsTab",
          "myCoursesInAttendenceReport",
          "instructorAssessmentReport",
          "surveyCourseReport",
          "surveyParticipantsReport"
        );
        userPermissions.push("viewSurveyApprovalTab", "SurveyApprovalAdmin"); //Survey Approvals
      }
      if (course.pivot.courserole_id == 2) {
        // userPermissions = []
        // course student permissions here
        userPermissions.push("viewInstitution"); //institution permission
        userPermissions.push("viewAssessmentTab", "studentAssessmentAccess"); //Assessment
        userPermissions.push("viewAssignmentTab", "studentAssignment"); //Assignment
        userPermissions.push("viewLiveClassTab", "studentLiveClass"); //Live Classes
        userPermissions.push("ViewInstitutionTimeTableTab", "viewStudentTab"); //instructor timetable
        userPermissions.push("viewSurveyTab", "surveyStudent"); // Survey Permissions
        userPermissions.push("viewSurveyApprovalTab", "SurveyApprovalStudent"); //Survey Approvals
      }
    });

    return userPermissions;
  },

  async compileInstitutionUserRoles(rolesAndPermissions) {
    let institutionUserRolesArray = [];
    let role = { name: null, role_on: [] };

    //-------------------------------institution---------------------------------
    if (rolesAndPermissions.institutionRole.name == "admin") {
      institutionUserRolesArray.push({
        name: "Institution Admin",
        role_on: [],
      });
    }
    if (rolesAndPermissions.institutionRole.name == "teacher") {
      institutionUserRolesArray.push({
        name: "Institution Faculty",
        role_on: [],
      });
    }
    if (rolesAndPermissions.institutionRole.name == "student") {
      institutionUserRolesArray.push({
        name: "Institution Student",
        role_on: [],
      });
    }
    //-------------------------------group---------------------------------
    role = { name: null, role_on: [] };
    await rolesAndPermissions.groupRoles.forEach((group) => {
      if (group.pivot.usergrouprole_id == 1) {
        role.name = "Group Admin";
        role.role_on.push(group.name);
      }
    });
    if (role.name) institutionUserRolesArray.push(role);

    role = { name: null, role_on: [] };
    await rolesAndPermissions.groupRoles.forEach((group) => {
      if (group.pivot.usergrouprole_id == 2) {
        role.name = "Group Faculty";
        role.role_on.push(group.name);
      }
    });
    if (role.name) institutionUserRolesArray.push(role);

    //-------------------------------subgroup---------------------------------
    role = { name: null, role_on: [] };
    await rolesAndPermissions.subgroupRoles.forEach((subgroup) => {
      if (subgroup.pivot.usersubgrouprole_id == 1) {
        role.name = "Subgroup Admin";
        role.role_on.push(subgroup.name);
      }
    });
    if (role.name) institutionUserRolesArray.push(role);
    role = { name: null, role_on: [] };
    await rolesAndPermissions.subgroupRoles.forEach((subgroup) => {
      if (subgroup.pivot.usersubgrouprole_id == 2) {
        role.name = "Subgroup Student";
        role.role_on.push(subgroup.name);
      }
    });
    if (role.name) institutionUserRolesArray.push(role);

    //-------------------------------course---------------------------------
    role = { name: null, role_on: [] };
    await rolesAndPermissions.courseRoles.forEach((course) => {
      if (course.pivot.courserole_id == 1) {
        role.name = "Course Instructor";
        role.role_on.push(course.name);
      }
    });
    if (role.name) institutionUserRolesArray.push(role);
    role = { name: null, role_on: [] };
    await rolesAndPermissions.courseRoles.forEach((course) => {
      if (course.pivot.courserole_id == 2) {
        role.name = "Course Student";
        role.role_on.push(course.name);
      }
    });
    if (role.name) institutionUserRolesArray.push(role);

    return institutionUserRolesArray;
  },
};

export default acl;

import Getters from "./getters";
import Mutations from "./mutation";
import Actions from "./actions";
export default {
  state: {
    custom_namings: {},
  },
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
};

<template>
  <div>
    <b-modal
      id="fillUserProfile"
      title="BootstrapVue"
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      hide-header-close
      hide-header
      hide-footer
      centered
      size="md"
    >
      <b-form @submit.prevent="createProfile()">
        <h6
          class="alert alert-success text-center"
          v-if="responseSuccess.created"
        >
          {{ responseSuccess.created[0] }}
        </h6>
        <div v-else>
          <h6 class="text-center text-warning">Your profile is incomplete!</h6>
          <h6 class="text-center text-warning">
            Please fill the basic details to proceed.
          </h6>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-group label="First Name">
              <b-form-input
                v-model="userProfile.first_name"
                :class="{ 'is-invalid': responseErrors.first_name }"
              ></b-form-input>
              <span class="text-danger" v-if="responseErrors.first_name">{{
                responseErrors.first_name[0]
              }}</span>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Last Name">
              <b-form-input
                v-model="userProfile.last_name"
                :class="{ 'is-invalid': responseErrors.last_name }"
              ></b-form-input>
              <span class="text-danger" v-if="responseErrors.last_name">{{
                responseErrors.last_name[0]
              }}</span>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <b-button
                  variant="primary"
                  size="md"
                  type="submit"
                  :disabled="profileBtn.disabled"
                  >{{ profileBtn.text }}</b-button
                >
                <b-button
                  variant="danger"
                  size="md"
                  @click="cancel()"
                  :disabled="profileBtn.disabled"
                  >Cancel</b-button
                >
              </div>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    // this.$bvModal.show("fillUserProfile");
    this.checkUserProfile();
  },
  data() {
    return {
      userProfile: {
        first_name: "",
        last_name: "",
      },
      responseErrors: {},
      responseSuccess: {},
      profileBtn: {
        text: "Submit",
        disabled: false,
      },
      userId: "",
    };
  },
  methods: {
    checkProfile() {
      this.$bvModal.show("fillUserProfile");
    },
    async checkUserProfile() {
      const url = this.$store.getters.getAPIKey.checkUserProfile;
      axios.get(url).then((response) => {
        if (response) {
          this.userId = response.data.id;
        }
      });
    },
    async createProfile() {
      if (this.userId == "") {
        return "Not Authenticated";
      }
      this.profileBtn.text = "Please Wait...";
      this.profileBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.createProfile + `${this.userId}`;
      axios
        .put(url, this.userProfile)
        .then(() => {
          this.responseSuccess["created"] = ["Profile created."];
          this.clearResponse();
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
        });
    },
    clearResponse() {
      this.profileBtn.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess.created) {
          this.responseSuccess = {};
          this.$emit("profileCreated");
          this.$bvModal.hide("fillUserProfile");
        }
        this.responseErrors = {};
        this.profileBtn.disabled = false;
      }, 3000);
    },
    cancel() {
      this.userProfile.first_name = "";
      this.userProfile.last_name = "";
      this.$bvModal.hide("fillUserProfile");
      this.$emit("cancelled");
    },
  },
};
</script>

<template>
  <div>
    <div class="youtubeLinks d-flex flex-wrap justify-content-between">
      <a
        :href="link.link"
        class="text-decoration-none border border-info rounded"
        target="_blank"
        v-for="(link, linkIndex) in youtubeLinks"
        :key="linkIndex"
      >
        <img :src="link.thumbnail" alt="ezygo" class="img-fluid" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.youtubeLinks = this.$ezygoLinks[this.$youtubeCategory];
    if (this.$store.getters.defaultInstitute.userRole == "Student") {
      this.youtubeLinks = this.youtubeLinks.filter((youtube) => {
        return youtube.instructor == true && youtube.student == true;
      });
    }
  },
  data() {
    return {
      youtubeLinks: [],
    };
  },
  methods: {},
};
</script>

<style >
.youtubeLinks a {
  margin-bottom: 10px;
}
</style>

import Vue from "vue";
export default {
  setCustomNamings(state, payload) {
    payload.forEach((custom) => {
      let sortIndex;
      if (custom.key_name == "mainGroup") {
        sortIndex = 1;
      } else if (custom.key_name == "userGroup") {
        sortIndex = 2;
      } else if (custom.key_name == "userGroupAdmin") {
        sortIndex = 3;
      } else if (custom.key_name == "userSubgroup") {
        sortIndex = 4;
      } else if (custom.key_name == "userSubgroupAdmin") {
        sortIndex = 5;
      } else {
        ("NOTHING");
      }
      if (
        custom.key_name != "faculty" &&
        custom.key_name != "groupStudent" &&
        custom.key_name != "courseInstructor" &&
        custom.key_name != "courseStudent"
      )
        Vue.set(state.custom_namings, custom.key_name, {
          sortIndex,
          ...custom,
        });
    });
  },
};

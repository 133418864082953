import store from "../../store";
import axios from "axios";

export default {
  async getCustomNamings(context) {
    const url = store.getters.getAPIKey.mainAPI + "/customnamings";
    const customNamings = await axios.get(url).then((res) => {
      return res.data;
    });
    if (customNamings.length == 0) {
      context.dispatch("getCustomNamingsOptions");
    } else {
      context.commit("setCustomNamings", customNamings);
    }
  },
  async getCustomNamingsOptions(context) {
    const url = store.getters.getAPIKey.mainAPI + "/customnamings/options";
    const naming = [];
    await axios.get(url).then((res) =>
      res.data.forEach((option) => {
        if (
          option != "faculty" &&
          option != "courseInstructor" &&
          option != "courseStudent" &&
          option != "groupStudent"
        )
          naming.push({
            key_name: option,
            custom_name: null,
          });
      })
    );
    await context.dispatch("storeCustomNamings", naming);
  },
  async storeCustomNamings(context, payload) {
    context;
    const url = store.getters.getAPIKey.mainAPI + "/customnamings";
    await axios.post(url, { custom_namings: payload });
    window.location.reload();
  },
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import acl from "./authorization/acl.js";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import axios from "axios";
import Multiselect from "vue-multiselect";
import moment from "moment";
import VueSmoothScroll from "vue2-smooth-scroll";
import draggable from "vuedraggable";
import vMultiselectListbox from "vue-multiselect-listbox";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";
import Loading from "./components/templates/Loading.vue";
import FillPersonalProfile from "./components/templates/Dashboard/user/FillPersonalProfile.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import Vuelidate from "vuelidate";
import YoutubeTutorials from "./components/templates/Dashboard/youtubeTutorials/YoutubeTutorials.vue";
import EzygoLinks from "./components/templates/Dashboard/youtubeTutorials/EzygoLinks.js";
YoutubeTutorials;
EzygoLinks;
// import customStyleForPrint from "./assets/css/style.css";

// let basePath= 'http://localhost:8080';
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    `./assets/css/style.css`,
  ],
};


// import MathfieldComponent from "./vue-mathlive.mjs";
// Vue.config.devtools = true;

import VueHtmlToPaper from "vue-html-to-paper";
Vue.use(VueHtmlToPaper, options);

import Clipboard from "v-clipboard";
import ezygoLinks from "./components/templates/Dashboard/youtubeTutorials/EzygoLinks.js";
Vue.use(Clipboard);

Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.use(VueToast);
axios.interceptors.request.use(
  async (request) => {
    // if request root domine == mail api then avoid intercepter header
    const mainAPI = store.getters.getAPIKey.mainAPI;
    const currentUrl = request.url;
    let token = await localStorage.getItem("token");
    if (currentUrl.includes(mainAPI + '/auditor')) {
      token = await localStorage.getItem("auditorAuthToken");
    }
    if (currentUrl.includes(mainAPI + '/publicexampublicuser')) {
      token = await localStorage.getItem("publicexamAuthToken");
    }
    if (currentUrl.includes(mainAPI) && token) {
      var modifiedRequest = { ...request };
      modifiedRequest.headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      return modifiedRequest;
      // request.headers = {
      //   Authorization: `Bearer ${token}`,
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // };
      // return request;
    }
    return request;
  },
  (error) => {
    // console.log(error)
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (respone) => {
    // console.log(respone)
    return respone;
  },
  async (error) => {
    if (error.response.status == 500) {
      Vue.$toast.error("An unidentified error occurred, please contact ezygo support", {
        position: "top",
        duration: 7000,
      });
    }
    if (error.response.status == 403) {
      Vue.$toast.error(error.response.data.message, {
        position: "top",
        duration: 6000,
      });
    }
    if (error.response.status == 422 && error.response.data.message != undefined) {
      const currentUrl = error.response.config.url;
      if (!currentUrl.includes('/lookup'))
        Vue.$toast.error(error.response.data.message, {
          position: "top",
          duration: 6000,
        });
    }
    if (error.response.status == 401) {
      // console.log(error.response.data);
      const mainAPI = store.getters.getAPIKey.mainAPI;
      const currentUrl = error.response.config.url;
      if (currentUrl.includes(mainAPI + '/auditor')) {
        localStorage.removeItem("auditorAuthToken");
        localStorage.removeItem("auditorAuthEmail");
        router.push('/auditor/login');
      }
      else if(currentUrl.includes(mainAPI + '/myprofile')){
          // do nothing to avoid issue
      }
      else if(currentUrl.includes(mainAPI + '/publicexampublicuser')){
        localStorage.removeItem("publicexamAuthToken");
				localStorage.removeItem("publicexamAuthEmail");
        router.push('/publicexam/login');
      }
      else{
        localStorage.removeItem("token");
        localStorage.removeItem("userPermissions");
        localStorage.removeItem("institutionUserRolesArray");
        router.push('/');
      }

    }
    return Promise.reject(error);
  }
);

Vue.prototype.$youtubeCategory = null;
Vue.prototype.$ezygoLinks = ezygoLinks;

Vue.mixin({
  methods: {
    closeCurrentTab: function () {
      window.close();
    },
    openYoutubeLinks: async function (youtubeCategoryName) {
      Vue.prototype.$youtubeCategory = await youtubeCategoryName;
      if (ezygoLinks[youtubeCategoryName].length > 1) {
        this.$bvModal.show("youtubeLinks");
      } else {
        window.open(ezygoLinks[youtubeCategoryName][0].link, "_blank");
      }
    },
  },
});

window.addEventListener("resize", handleResize);
handleResize();

function handleResize() {
  Vue.prototype.$screenSize = window.innerWidth;
}
Vue.prototype.$batchYears = [
  { value: null, text: "Select Year", disabled: true },
];
function prepareBatchYears() {
  let n = 14;
  for (let i = 0; i <= n; i++) {
    Vue.prototype.$batchYears.push({
      value: `${Number(2014 + i)}`,
      text: `${Number(2014 + i)}`,
    });
  }
}

prepareBatchYears();

// Vue.prototype.$settingAcedemicYears = [
//   { value: null, text: "Select Academic Year", disabled: true },
//   { value: "0", text: "Academic Year: All" },
//   { value: "2019-20", text: "Academic Year: 2019-20" },
//   { value: "2020-21", text: "Academic Year: 2020-21" },
//   { value: "2021-22", text: "Academic Year: 2021-22" },
//   { value: "2022-23", text: "Academic Year: 2022-23" },
// ];
Vue.prototype.$settingAcedemicYearsAndSemester = [
  { value: null, text: "Select Academic Year", disabled: true },
  { value: ["0", "0"], text: "Academic Year: All" },
  { value: ["2019-20", "odd"], text: "Semester: 2019-20 odd" },
  { value: ["2019-20", "even"], text: "Semester: 2019-20 even" },

  { value: ["2020-21", "odd"], text: "Semester: 2020-21 odd" },
  { value: ["2020-21", "even"], text: "Semester: 2020-21 even" },

  { value: ["2021-22", "odd"], text: "Semester: 2021-22 odd" },
  { value: ["2021-22", "even"], text: "Semester: 2021-22 even" },

  { value: ["2022-23", "odd"], text: "Semester: 2022-23 odd" },
  { value: ["2022-23", "even"], text: "Semester: 2022-23 even" },

  { value: ["2023-24", "odd"], text: "Semester: 2023-24 odd" },
  { value: ["2023-24", "even"], text: "Semester: 2023-24 even" },

  { value: ["2024-25", "odd"], text: "Semester: 2024-25 odd" },
  { value: ["2024-25", "even"], text: "Semester: 2024-25 even" },
];

Vue.prototype.$acedemicYears = [
  { value: null, text: "Select Year", disabled: true },
  { value: "2019-20", text: "2019-20" },
  { value: "2020-21", text: "2020-21" },
  { value: "2021-22", text: "2021-22" },
  { value: "2022-23", text: "2022-23" },
  { value: "2023-24", text: "2023-24" },
  { value: "2024-25", text: "2024-25" },
];
Vue.prototype.$acedemicSemesters = [
  { value: null, text: "Select Semester", disabled: true },
  { value: "odd", text: "Odd Semester" },
  { value: "even", text: "Even Semester" },
];

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("multiselect", Multiselect);
Vue.component("draggable", draggable);
Vue.component("v-multiselect-listbox", vMultiselectListbox);
Vue.component("load-data", Loading);
Vue.component("fill-profile", FillPersonalProfile);
Vue.component(VueCountdown.name, VueCountdown);
Vue.component("youtubeTutorial", YoutubeTutorials);
Vue.use(Vuelidate);
// Vue.use(VueFbCustomerChat, {
//   page_id: 101959358768721, //  change 'null' to your Facebook Page ID,
//   theme_color: '#333333', // theme color in HEX
//   locale: 'en_US', // default 'en_US'
// })
Vue.prototype.moment = moment;
Vue.use(moment);
moment.suppressDeprecationWarnings = true;
Vue.use(VueSmoothScroll, {
  duration: 400,
  updateHistory: false,
});
// Vue.prototype.$http = Axios
// Vue.use(axios)
Vue.prototype.$axios = axios;

// import 'jodit/build/jodit.min.css'
// import JoditVue from 'jodit-vue'
// Vue.use(JoditVue)

new Vue({
  data: {
    subContent: { // dashboard UI settings
      enableMainContent: true,
      subContentComponent: "",
      subContentData: {},
    },
    default_academic_year: [null, null], // default : ['0', '0'] used to notify select to view dashboard cards
  },
  methods: {
    clearSubContent() {
      this.subContent.enableMainContent = true;
      this.subContent.subContentComponent = "";
      this.subContent.subContentData = {};
    },
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");


import Actions from "./actions";
import Mutations from "./mutation";
import Getters from "./getters.js";

export default {
  state: {
    loading: false,
    addInstitute: false,
    institionApproval: true,
    myIinstitutionEnrollmets: [],
    institutions: [],
    filteredUniqueInstitutions: {},
    institutionUsers: {},
    selectedInstitute: {},
    defaultInstitutionUserId: null,
    defaultInstituteId: null,
    smsBalance: null,
    instituteSettings: false
  },
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
};
